<template>
  <b-container class="bv-example-row mb-3" fluid>
    <div>
      <b-card>
        <div id="printMe">
          <b-row>
            <b-col cols="4" class="top-row-col-4">
              <h4>Vardiya Gider Listesi</h4>
            </b-col>
            <b-col cols="8" class="text-right unPrint">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  :to="'/vardiyaOzet/' + vardiyaID"
                  class="myButton mr-50"
              >
                <feather-icon icon="PieChartIcon" class="mr-50"/>
                Vardiya Özeti
              </b-button>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  v-print="'#printMe'"
              >
                <feather-icon icon="PrinterIcon" class="mr-50"/>
                Gider Kayıtlarını Yazdır
              </b-button>
            </b-col>
          </b-row>
          <hr/>
          <table class="table table-striped mt-1 table-hover">
            <thead>
            <tr>
              <th scope="col">TUTAR</th>
              <th scope="col">AÇIKLAMASI</th>
              <th scope="col">TARİH</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(gider, index) in giderList"
                :key="index"
            >
              <td>{{ formatPrice(gider.tutar) }} TL</td>
              <td>{{ gider.aciklama }}</td>
              <td>{{ gider.kayitTarih }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BSpinner,
  },
  data() {
    return { vardiyaID: this.$route.params.id }
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2)
          .replace('.', ',')
      return val.toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
  computed: {
    giderList() {
      return store.getters.getGider
    },
  },
  created() {
    this.$store
        .dispatch('giderKayitlari', this.vardiyaID)
        .then((res, position = position = 'bottom-right') => {
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('giderAction', 'remove')
  },
  directives: {
    Ripple,
  },
}
</script>

<style scoped>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #285274;
  color: white;
}

[dir] .table thead th {
  background-color: 2px solid #285274;
  padding: 10px !important;
}

[dir] .table th,
[dir] .table td {
  padding: 0 !important;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #93bee1 !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: space-between;
}

.card-body {
  padding: 10px 30px;
}

.top-row-col-4 {
  display: inherit;
  align-items: end;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #0ea9d7;
  color: white;
}
</style>
